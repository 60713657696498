<div class="upload-container">
  <div class="file-row">
    <label for="file-upload" class="file-upload-label">
      <button class="choose-file-btn" (click)="triggerFileUpload()">Choose File</button>
    </label>
    <input type="file" id="file-upload" (change)="onFileSelected($event)" hidden accept=".pdf, .png, .jpeg, .jpg" />


    <div *ngIf="this.fileId" class="file-preview " (click)="downloadFile()">
      <img class="document-icon" [src]="fileIconPath" alt="file icon" />

      <span class="file-name">{{this.fileName}}</span>
    </div>
  </div>
  <small class="file-hint">Only supports .pdf .png .jpg .jpeg files</small>
  <div class="error-message" *ngIf="errorMessage">
    {{ errorMessage }}
  </div>
</div>