import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  effect,
} from "@angular/core";
import { AppSettings } from "../../../app.settings";
import { Settings } from "../../../app.settings.model";
import { MenuService } from "../../services/menu.service";
import { MatDialog } from "@angular/material/dialog";
import { SearchUnitNumberComponent } from "src/app/shared/components/dialogbox/search-unit-number/search-unit-number.component";
import { Menu, MenuItem } from "../menu/menu.model";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Platform } from "@ionic/angular";
import { ConfigService } from "src/app/shared/services/config/config.service";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SidenavComponent implements OnInit {
  icon: boolean = false;
  isMobile: boolean = false;
  public menuItems: Array<Menu>;
  public settings: Settings;
  flags: Record<string, boolean | undefined>;
  technicalSupportEnabled: boolean = false;
  billingSupportEnabled: boolean = false;

  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private box: MatDialog,
    private profileService: ProfileService,
    private platform: Platform,
    public auth: AuthenticationService,
    private configService: ConfigService
  ) {
    this.settings = this.appSettings.settings;
    effect(async () => {
      this.flags = await this.profileService.getAllMyussFlags();
      this.technicalSupportEnabled = this.configService.getConfigProperty(
        "TECHNICAL_SUPPORT_ENABLED"
      );
      this.billingSupportEnabled = this.configService.getConfigProperty(
        "BILLING_SUPPORT_ENABLED"
      );
      if (this.flags?.myussCasesEnabled || this.flags?.myussOrdersEnabled) {
        this.mobileMenuItem.unshift({
          icon: "assets/img/icons/service_activity.svg",
          label: "Service Activity",
          route: "/home/notification",
        });
      }
      if (this.technicalSupportEnabled) {
        this.mobileMenuItem.splice(
          this.flags?.myussCasesEnabled || this.flags?.myussOrdersEnabled
            ? 1
            : 0,
          0,
          {
            icon: "assets/img/icons/support.svg",
            label: "Support",
            route: "/support/technical-support",
          }
        );
      }
      if (this.billingSupportEnabled && this.technicalSupportEnabled) {
        this.mobileMenuItem.splice(
          this.flags?.myussCasesEnabled || this.flags?.myussOrdersEnabled ? 2
            : 1,
          0,
          {
            icon: "assets/img/icons/Billing_Support_Menu.svg",
            label: "Billing Support",
            route: "/support/billing-support",
          }
        );
      }
      if (this.billingSupportEnabled && !this.technicalSupportEnabled) {
        this.mobileMenuItem.splice(
          this.flags?.myussCasesEnabled || this.flags?.myussOrdersEnabled ? 1
            : 0,
          0,
          {
            icon: "assets/img/icons/Billing_Support_Menu.svg",
            label: "Billing Support",
            route: "/support/billing-support",
          }
        );
      }
    });
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    if (window.innerWidth <= 960) {
      this.isMobile = true;
    }
  }

  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 960) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  public closeSubMenus() {
    let menu = document.getElementById("vertical-menu");
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains("expanded")) {
            child.children[0].classList.remove("expanded");
            child.children[1].classList.remove("show");
          }
        }
      }
    }
  }

  mobileMenuItem: MenuItem[] = [
    {
      icon: "assets/img/icons/faq.svg",
      label: "FAQ",
      externalLink: "https://myuss.helpdocs.io/",
      route: "",
    },
  ];

  collapse() {
    const drawer = document.querySelector(".mat-drawer-content") as HTMLElement;

    if (this.settings.menuType == "mini") {
      this.icon = false;
      this.settings.menuType = "default";
      drawer ? (drawer.style.marginLeft = "220px") : null;
      return;
    }
    this.icon = true;
    this.settings.menuType = "mini";
    drawer ? (drawer.style.marginLeft = "66px") : null;
    return;
  }

  getAriaLabel(): string {
    return this.icon
      ? "Expands the menu to show more options."
      : "Collapses the menu to hide options.";
  }

  search() {
    this.box.open(SearchUnitNumberComponent, {
      minWidth: "280px",
      maxWidth: "450px",
      panelClass: "search-unit-number",
    });
  }
  showSearch() {
    const selectedAccount = this.profileService.selectedAccount();
    return (
      !this.platform.is("hybrid") &&
      this.profileService.selectedAccount().myussModules
        ?.myussAssetScanningEnabled
    );
  }
  logout() {
    // this.auth.getUserName()
    this.auth.logout();
    localStorage.setItem("logoutEvent", "logout" + Math.random());
    const accountId = localStorage.getItem("accountId") || "";
    localStorage.clear();
    localStorage.setItem("accountId", accountId);
  }
}
