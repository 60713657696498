import { HttpClient } from '@angular/common/http';
import { Component, computed, signal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { ConfigService } from 'src/app/shared/services/config/config.service';
import { InitialUrlService } from 'src/app/shared/services/initial-url/initial-url.service';
import { phoneNumber } from "src/assets/config/constants";
@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {
  email: string;
  source = signal('none');
  settings: Settings;
  emailSent: boolean;
  phoneNumber: string;

  constructor(
    private configService: ConfigService,
    private appSettings: AppSettings,
    private http: HttpClient,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute,
    private initalUrlService: InitialUrlService,
  ) {
    this.settings = this.appSettings.settings;
   }

  ngOnInit(): void {
    localStorage.removeItem("initialUrl");
    this.initalUrlService.resetInitalUrl();
    this.phoneNumber = phoneNumber;
    this.route.url.subscribe((url) => {
      console.log('url', url);
      if (url[1]?.path === 'thank-you') {
        this.emailSent = true;
      }
    });
    this.route.queryParams.subscribe(params => {
      this.email = params.email;
      this.source.set(params.source);
    });
  }

  public showMyUSS = computed(() => this.source() !== 'sidetrade');
  public showSidetrade = computed(() => this.source() == 'sidetrade');

  public portalName = computed(() => {
    const source = this.source();
    if (source === 'sidetrade') {
      return 'the USS Billing Portal';
    } else {
      return 'MyUSS';
    }
  });

  ngAfterViewInit() {
    setTimeout(() => {
      this.settings.loadingSpinner = false;
    });
  }

  initiatePasswordReset(): void {
    console.log('initiatePasswordReset');
    const domain = this.configService.config.auth0.domain;
    const clientId = this.configService.config.auth0.clientId;
    const url = `https://${domain}/dbconnections/change_password`;
    const body = {
      client_id: clientId,
      email: this.email,
      connection: 'Username-Password-Authentication',
    };
    const resp = firstValueFrom(this.http.post(url, body, {
      responseType: 'text',
    })).then((resp) => {
      this.toastrService.success('Password reset email sent to ' + this.email, 'Success');
      this.emailSent = true;
      this.router.navigate(['thank-you'], { relativeTo: this.route });
      console.log(resp);
    }).catch((err) => {
      this.toastrService.error(`Error sending password reset email, please contact ${phoneNumber}`, 'Error');
      console.log(err);
    });
  }

}
