@if(contractFlag){
<section>
  <!-- search for orders list -->
  @if(endPoint === 'orders'){
  <div class="search_filter mt-3 w-100">
    <div
      class="search_filter_css"
      [ngClass]="{ 'w-100': contractsFilter == 'Canceled' }"
    >
      <mat-form-field class="search_icon" appearance="outline">
        <mat-icon
          matPrefix
          aria-label="Search orders through order id or site address or status"
          role="img"
          aria-hidden="false"
          >search</mat-icon
        >
        <mat-label>Search</mat-label>
        <input
          matInput
          (keyup)="applyFilter($event)"
          placeholder="search"
          #searchInput
        />
      </mat-form-field>
    </div>
    @if(contractsFilter == 'Activated,Suspended,Draft'){
    <div class="menu_Filter pl-2">
      <button class="filter_button" [matMenuTriggerFor]="menuFilter">
        <img
          src="../../../../assets/img/icons/filter.png"
          role="img"
          aria-label="Filter orders based on status"
          aria-hidden="false"
        />
      </button>
      <mat-menu #menuFilter="matMenu">
        <p class="px-3 py-2" (click)="onFilterSelect('All')">All</p>
        @for(status of allStatus; track status){
        <p class="px-3 py-2" (click)="onFilterSelect(status)">
          {{ status }}
        </p>

        }
      </mat-menu>
    </div>

    }
  </div>

  }

  <div
    [ngClass]="{
      button_filter: projectTypeFilter.value === 'Orders with no project assign'
    }"
  >
    @if(endPoint === 'orders' && flags?.myussProjectsEnabled && projectEnabled){
    <div>
      <mat-form-field class="project_filter w-100" appearance="outline">
        <mat-label>Project filter</mat-label>
        <mat-select
          placeholder="Ex. Active"
          (selectionChange)="onProjectFilter()"
          [formControl]="projectTypeFilter"
        >
          <mat-option value="All">All</mat-option>
          <mat-option
            *appHideIfReadOnly
            [value]="'Orders with no project assign'"
            >Orders with no project assign</mat-option
          >
          @for(project of projectList; track project){
          <mat-option [value]="project.id">{{ project.name }}</mat-option>

          }
        </mat-select>
        <mat-icon
          matPrefix
          role="img"
          aria-label="Filter orders based on project"
          aria-hidden="false"
          ><img
            src="../../../../assets/img/icons/filter.svg"
            aria-hidden="true"
        /></mat-icon>
      </mat-form-field>
    </div>

    } @if(projectTypeFilter.value === 'Orders with no project assign'){
    <div>
      <button
        class="mb-4"
        [ngClass]="{
          font_size: projectTypeFilter.value === 'Orders with no project assign'
        }"
        (click)="onAssignProject()"
        mat-raised-button
        color="primary"
      >
        Assign to Project
      </button>
    </div>

    }
  </div>

  <!-- search for case list -->
  <div class="search-and-filter">
  @if(endPoint ===
    'cases' || endPoint === 'isOrderScreen'){
    <div>
      <div class="w-100">
        <mat-form-field
          class="case-list-search prefix-icon-position w-100"
          appearance="outline"
        >
          <mat-icon matPrefix class="center-icon">search</mat-icon>
          <mat-label [ngClass]="{ 'ms-5': isSearchRoute }">Search</mat-label>
          <input
            matInput
            (keyup)="searchCases($event)"
            placeholder="search"
            #searchInput
            (click)="toggle($event)"
            (blur)="toggle($event)"
          />
        </mat-form-field>
      </div>

      <div class="child child-two">
        <mat-slide-toggle
          [aria-label]="isCaseClosed.toString()"
          color="primary"
          [checked]="isCaseClosed"
          (change)="onToggleChange($event)"
        >
          @if(screen != 'technical-support-list'){
          <span> Show closed cases </span>

          }
        </mat-slide-toggle>
      </div>
      @if(isCaseClosed){
      <!-- This is the filter button -->
      <div class="filter">
        <mat-form-field class="case-list-search" appearance="outline">
          <mat-label>Duration</mat-label>
          <mat-select
            [(ngModel)]="selectedOption"
            (selectionChange)="selectedValue()"
          >
            <!-- <mat-option value="None">None</mat-option> -->
            <mat-option value="30 days">1 Month</mat-option>
            <mat-option value="60 days">2 Months</mat-option>
            <mat-option value="90 days">3 Months</mat-option>
            <mat-option value="Custom Date">Custom Date</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      }

      <div class="ms-3">
        <div class="date-filter">
          @if(showFromToDate){
          <div class="filter">
            <mat-form-field
              class="example-full-width mt-4"
              class="date-filter-css"
              appearance="outline"
            >
              <mat-label>From Date</mat-label>
              <input
                matInput
                [matDatepicker]="fromDatePicker"
                (dateChange)="selectedStartDate($event)"
                [max]="maxDate"
              />
              <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
              <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #fromDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          }
        </div>
        <div class="date-filter">
          @if(showFromToDate){
          <div class="filter">
            <mat-form-field
              class="example-full-width mt-2"
              class="date-filter-css"
              appearance="outline"
            >
              <mat-label>To Date</mat-label>
              <input
                matInput
                [matDatepicker]="toDatePicker"
                (dateChange)="selectedEndDate($event)"
                [max]="maxDate"
                [min]="minEndDate"
              />
              <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
                <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
              </mat-datepicker-toggle>
              <mat-datepicker #toDatePicker></mat-datepicker>
            </mat-form-field>
          </div>

          } @if(selectedOption == 'Custom Date'){
          <div>
            <button
              [disabled]="bothDateSelected()"
              class="custom-date-css"
              mat-raised-button
              color="primary"
              class="mt-1 ms-2"
              (click)="getCustomDateClosedCases()"
            >
              Search
            </button>
          </div>

          }
        </div>
      </div>
    </div>

    } 
  </div>
  @if(mobileView && (screen == 'technical-support-list' ||
  'billing-support-list') && endPoint != 'cases' && endPoint != 'isOrderScreen' && endPoint != 'orders'){
  <!-- search for technical support  -->
  <div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      @if(screen == 'billing-support-list'){
      <div>
        <button
          class="get_support"
          mat-raised-button
          color="primary"
          (click)="onBillingSupport()"
        >
          Get Support
        </button>
      </div>

      } @if(screen == 'technical-support-list'){
      <button
        class="get_support"
        mat-raised-button
        color="primary"
        (click)="getSupport()"
      >
        Get Support
      </button>

      }
      @if(screen == 'technical-support-list' || screen == 'billing-support-list'){
        <mat-slide-toggle
        [aria-label]="isCaseClosed.toString()"
        color="primary"
        [checked]="isCaseClosed"
        (change)="onToggleChange($event)"
      >
        <span> Show closed </span>
      </mat-slide-toggle>

      }

     
    </div>
    @if(!isCaseClosed){
    <div class="pt-2">
      <mat-form-field
        appearance="outline"
        class="search prefix-icon-position w-100"
      >
        <mat-icon matPrefix>search</mat-icon>
        <input
          matInput
          (keyup)="searchCases($event)"
          placeholder="Search"
          #searchInput
        />
      </mat-form-field>
    </div>

    } @if(isCaseClosed){
    <div class="pt-2">
      <div class="search_filter w-100">
        <div class="search_filter_css">
          <mat-form-field
            appearance="outline"
            class="search prefix-icon-position w-100"
          >
            <mat-icon matPrefix>search</mat-icon>
            <input
              matInput
              (keyup)="searchCases($event)"
              placeholder="Search"
              #searchInput
            />
          </mat-form-field>
        </div>
        <div class="menu_Filter pl-2">
          <button class="filter_button mt-2" [matMenuTriggerFor]="menuFilter">
            <img
              src="../../../../assets/img/icons/filter.png"
              role="img"
              aria-label="Filter orders based on status"
              aria-hidden="false"
            />
          </button>
          <mat-menu #menuFilter="matMenu">
            <div class="mat-menu-container">
              @for(duration of durationList; track duration){
              <p class="px-3 py-2" (click)="selectDuration(duration.value)">
                {{ duration.name }}
              </p>

              }
            </div>
          </mat-menu>
        </div>
      </div>
      @if(showFromToDate){
      <div class="date-filter">
        <mat-form-field
          class="example-full-width mt-4"
          class="date-filter-css"
          appearance="outline"
        >
          <mat-label>From Date</mat-label>
          <input
            matInput
            [matDatepicker]="fromDatePicker"
            (dateChange)="selectedStartDate($event)"
            [max]="maxDate"
          />
          <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
          <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #fromDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          class="example-full-width mt-2"
          class="date-filter-css"
          appearance="outline"
        >
          <mat-label>To Date</mat-label>
          <input
            matInput
            [matDatepicker]="toDatePicker"
            (dateChange)="selectedEndDate($event)"
            [max]="maxDate"
            [min]="minEndDate"
          />
          <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
          </mat-datepicker-toggle>
          <mat-datepicker #toDatePicker></mat-datepicker>
        </mat-form-field>
        <button
          [disabled]="bothDateSelected()"
          mat-raised-button
          color="primary"
          class="mb-4"
          (click)="getCustomDateClosedCases()"
        >
          Search
        </button>
      </div>

      }
    </div>

    } @if(screen == 'technical-support-list'){
    <p class="technical-support-navigation pt-0 mt-0 mb-3">
      Note - For changes to existing orders, please use the
      <a [routerLink]="['/orders']" target="_self"> Order module</a>
    </p>

    }
  </div>

  }

  <div [ngClass]="isIos ? 'card_size_ios' : 'card_size'">
    @if(endPoint === 'orders'){
    <!-- for order list -->
    <div>
      @for(contractData of paginatedContracts; track contractData ){
        <div
        class="card_div order_list pb-2"
      
      >
        <mat-card class="custom-card card_css">
          <mat-card-header
            class="card_header"
            fxLayoutAlign="space-between center"
          >
            <mat-card-title class="m-0">
              @if(projectTypeFilter.value === 'Orders with no project assign'){
              <mat-checkbox
                color="primary"
                (change)="onChecked(contractData.quoteId, $event)"
              ></mat-checkbox>

              }

              {{ contractData.name }}</mat-card-title
            >
            <div class="px-2">
              <div class="action-button">
                <button
                  matTooltip="Edit Order"
                  [ngClass]="{
                    'disable-action':
                      contractData.status != 'Active' ||
                      contractData.contractNumber == '',
                    'active-action':
                      contractData.status === 'Active' ||
                      contractData.contractNumber
                  }"
                  (click)="
                    contractData.status === 'Active' &&
                      editOrder(contractData.contractId)
                  "
                  [disabled]="
                    contractData.status != 'Active' &&
                    contractData.contractNumber == ''
                  "
                  class="icon-button"
                >
                  @if(!isInActiveAccount){
                  <img
                    src="../../../../assets/img/icons/edit_square_icon.svg"
                    alt="edit icon to edit user details"
                  />

                  }
                </button>
                @if(enhanceQuoteUiFlag && !isInActiveAccount){
                <button
                  (click)="onClone(contractData.quoteId)"
                  class="icon-button"
                  matTooltip="Clone Order"
                >
                  <img
                    src="../../../../assets/img/icons/clone-icon.svg"
                    alt="clone icon to clone the order"
                  />
                </button>

                }

                <div
                  *ngIf="
                    contractData.status == 'Active' ||
                      contractData.status == 'Canceled';
                    else other_Status
                  "
                >
                  <div
                    *ngIf="
                      contractData.status == 'Active' &&
                      contractData.contractNumber
                    "
                    class="new px-3"
                  >
                    {{ contractData.status }}
                  </div>
                  <div
                    *ngIf="
                      contractData.status == 'Active' &&
                      contractData.contractNumber == ''
                    "
                    class="other_status px-3"
                  >
                    In-progress
                  </div>

                  <div
                    *ngIf="contractData.status == 'Canceled'"
                    class="closed px-3"
                  >
                    {{ contractData.status }}
                  </div>
                </div>
              </div>
              <ng-template #other_Status>
                <div class="other_status px-3">
                  {{ contractData.status }}
                </div>
              </ng-template>
            </div>
          </mat-card-header>
          <mat-card-content (click)="onVisibility(contractData)" class="py-3">
            <table class="order_list">
              <tr>
                <td>Site Address</td>
                <td class="site_address_css padd_css">
                  {{ contractData?.shippingAddress || "-" }}
                </td>
              </tr>
              <tr>
                <td>Payment Method</td>
                <td>
                  <div fxLayoutAlign="start center">
                    @if(contractData.easyPayDetails?.cardNo){
                    <p>
                      <span
                        *ngIf="contractData.easyPayMode == 'card'"
                        class="p-1 pl-1"
                        fxLayoutAlign="start center"
                      >
                        <img
                          [src]="
                            cardIcon +
                            contractData.easyPayDetails?.cardBrand +
                            '.png'
                          "
                          alt="Payment method card logo"
                          *ngIf="
                            [
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          height="20px"
                          class="pl-1"
                        />
                        <mat-icon
                          *ngIf="
                            ![
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          class="p-1"
                          >credit_card</mat-icon
                        >
                      </span>

                      <mat-icon
                        *ngIf="contractData.easyPayMode == 'bank'"
                        aria-label="Payment method bank logo"
                        role="img"
                        aria-hidden="false"
                        class="p-1"
                        >account_balance</mat-icon
                      >
                    </p>

                    } @if(contractData.easyPayDetails?.cardNo){
                    <p>
                      <span
                        *ngIf="contractData.easyPayMode == 'card'"
                        class="p-1 pl-1"
                        fxLayoutAlign="start center"
                      >
                        <img
                          [src]="
                            cardIcon +
                            contractData.easyPayDetails?.cardBrand +
                            '.png'
                          "
                          alt="Payment method card logo"
                          *ngIf="
                            [
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          height="20px"
                          class="pl-1"
                        />
                        <mat-icon
                          *ngIf="
                            ![
                              'jcb',
                              'discover',
                              'diners',
                              'visa',
                              'mastercard',
                              'amex'
                            ].includes(
                              (contractData?.easyPayDetails)!.cardBrand
                            )
                          "
                          class="p-1"
                          >credit_card</mat-icon
                        >
                      </span>

                      <mat-icon
                        *ngIf="contractData.easyPayMode == 'bank'"
                        aria-label="Payment method bank logo"
                        role="img"
                        aria-hidden="false"
                        class="p-1"
                        >account_balance</mat-icon
                      >
                    </p>

                    } @if(contractData.easyPayDetails?.cardNo){
                    <p>****</p>

                    }

                    <p class="cardNo_div padd_css">
                      {{
                        contractData.easyPayDetails?.cardNo
                          ? contractData.easyPayDetails?.cardNo
                          : "Manual"
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>

            <mat-expansion-panel
              (click)="$event.stopPropagation()"
              [disabled]="contractData?.casesCount === 0"
              class="mt-2"
            >
              <mat-expansion-panel-header>
                @if(contractData?.casesCount == 0){
                <p class="open-case">
                  Open Cases <span class="case-count">{{ 0 }}</span>
                </p>

                } @if(contractData?.casesCount !== 0){
                <p class="open-case">
                  Open Cases
                  <span class="case-count">{{ contractData?.casesCount }}</span>
                </p>

                }
              </mat-expansion-panel-header>
              <mat-divider></mat-divider>

              <div class="flex-container">
                {{ getCaseTypeCount(contractData.caseTypeWiseCasesCount) }}
                @if(contractData.casesCount !== 0){
                <div>
                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/ReduceQuantity.svg"
                      alt="Change Quantity image"
                    />
                    <p class="case-name">
                      {{ cases[0].caseType }}: {{ cases[0].caseCount }}
                    </p>
                  </div>

                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/AddQuantity.svg"
                      alt="Change Quantity image"
                    />
                    <p class="case-name">
                      {{ cases[1].caseType }}: {{ cases[1].caseCount }}
                    </p>
                  </div>

                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/AddNewUnit.svg"
                      alt="Add New Unit Type image"
                    />
                    <p class="case-name">
                      {{ cases[2].caseType }}: {{ cases[2].caseCount }}
                    </p>
                  </div>

                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/ChangeServiceFrequency.svg"
                      alt="Change Service Frequency image"
                    />
                    <p class="case-name">
                      {{ cases[3].caseType }}: {{ cases[3].caseCount }}
                    </p>
                  </div>

                  <div class="caseTypes">
                    <img
                      src="../../../../../assets/img/Move.svg"
                      alt="Move image"
                    />
                    <p class="case-name">
                      {{ cases[4].caseType }}: {{ cases[4].caseCount }}
                    </p>
                  </div>
                </div>

                }
              </div>
            </mat-expansion-panel>
          </mat-card-content>
        </mat-card>
      </div>

      }
     
      <mat-paginator
        #contractsListPaginator
        [length]="contracts?.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="onPageChange($event)"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>

    } @if(endPoint !== 'orders' && isCases){
    <!-- for case list -->
    <div>
      @for(case of paginatedCases; track case){
        <div
        class="card_div order_list pb-2"
        (click)="viewCaseDetails(case.id)"
        
      >
        <mat-card class="custom-card card_css">
          <mat-card-header
            class="card_header"
            fxLayoutAlign="space-between center"
          >
            @if( screen != 'technical-support-list' && screen !=
            'billing-support-list'){
            <mat-card-title class="m-0"
              >Case No:{{ case.caseNumber }}</mat-card-title
            >

            } @if(screen == 'technical-support-list' || screen ==
            'billing-support-list'){
            <mat-card-title class="m-0"
              >Support No:{{ case.caseNumber }}</mat-card-title
            >
            }

            <div class="mx-1">
                 <div>
                  @if(case?.status){
                    <div [ngClass]="getStatusClass(case.status) + ' px-3'">
                      {{getStatusText(case.status)}}
                    </div>
                  }
               </div>
            </div>
          </mat-card-header>
          <mat-card-content class="py-3">
            <table class="order_list">
              @if(endPoint == 'cases'){
              <tr>
                <td>Site Address</td>
                <td class="cases-parameter">
                  @if(case.orderAddress?.street || case.orderAddress?.city ||
                  case.orderAddress?.state || case.orderAddress?.zipcode){
                  <ng-container
                    *ngIf="
                      case.orderAddress?.street ||
                      case.orderAddress?.city ||
                      case.orderAddress?.state ||
                      case.orderAddress?.zipcode
                    "
                  >
                    {{ case.orderAddress?.street }}<br />
                    {{ case.orderAddress?.city }},
                    {{ case.orderAddress?.state }}
                    {{ case.orderAddress?.zipcode }}
                  </ng-container>

                  } @else {
                  <ng-template #blankTd>-</ng-template>

                  }
                </td>
              </tr>

              }

              <tr>
                <td>Opening Date</td>
                <td class="cases-parameter">
                  {{ case.createdDate | date : "MM/dd/yyyy" }}
                </td>
              </tr>
              <tr>
                <td>Last Updated</td>
                <td class="cases-parameter">
                  {{ case.lastModifiedDate | date : "MM/dd/yyyy" }}
                </td>
              </tr>
              @if( (screen == 'technical-support-list' ||
              'billing-support-list') && endPoint != 'cases'){
              <tr>
                <td>Subject</td>
                <td class="cases-parameter">
                  {{ case.subject }}
                </td>
              </tr>

              } @if( endPoint == 'cases'){
              <tr>
                <td>Request Type</td>
                <td class="cases-parameter">{{ getCaseType(case.type) }}</td>
              </tr>

              } @if( endPoint == 'cases'){
              <tr>
                <td>MyUss Case Type</td>
                <td class="cases-parameter">{{ case.myussCaseType }}</td>
              </tr>

              } @if(endPoint == 'cases'){
              <tr>
                <td>Order ID</td>
                <td class="cases-parameter">
                  {{ getOrderNumber(case.orderNumber) }}
                </td>
              </tr>

              }
            </table>
          </mat-card-content>
        </mat-card>
      </div>

      }
      
      <mat-paginator
        #caseListPaginator
        [length]="allCases?.length"
        [pageSize]="pageSize"
        [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="onPageChangeForCases($event)"
        [showFirstLastButtons]="true"
      >
      </mat-paginator>
    </div>

    } @if(!isCases && !isOrderScreen){

    <div class="mt-1" fxLayoutAlign="center">
      @if( screen != 'technical-support-list' && screen !=
      'billing-support-list'){
      <h2 class="no-cases">There are no cases related to the account.</h2>

      } @if((screen == 'technical-support-list' || 'billing-support-list') && endPoint != 'cases'){
      <h2 class="no-cases">There are no support request.</h2>

      }
    </div>

    } @if(!isOrderCases && isOrderScreen){
    <div class="mt-1" fxLayoutAlign="center">
      <h2 class="no-cases">There are no cases related to the order.</h2>
    </div>

    }

    <div class="no_Data" *ngIf="!contractFlag || contracts?.length == 0">
      <p>No data available</p>
    </div>
  </div>
</section>

}
