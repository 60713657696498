<div class="title_button p-2">
  <div class="title_text_wrapper">
    <h3 [ngClass]="{'title_text':screenName=='editOrder'}">Location Assignment</h3>
    <!-- <p>Lorem ipsum dolor sit amet</p> -->
  </div>
  <div class="add_btn_wrapper" *ngIf="(MobileEditOrderScreen != 'editOrderChangeQuantity' ||  screenName != 'editOrder') && urlSegments">
    <button
      mat-raised-button
      color="primary"
      class="add_Location"
      (click)="openAddLocationPopup()"
    >
    <mat-icon>add</mat-icon>
      Add Location
    </button>
  </div>
</div>
<section class="p-2">

  <div class="size_scroll mt-3" *ngIf="urlSegments">
    <div
      class="new_location p-3 mt-2"
      *ngFor="let location of newLocationValues"
    >
      <div class="site_details">
        <div class="map_icon_wrapper" fxLayoutAlign="center center">
          <img
            src="../../../../assets/img/icons/map.svg"
            alt="Location pin"
          />
        </div>

        <div class="px-2">
          <div class="site_name">
            Location name: {{ location?.address?.siteName }}
          </div>

          <div class="text_blue">
            Placement Note: {{ location?.address?.instructions }}
          </div>
          <!------When screen is quote creation site details step 4 , show this div---->
          <div  class="tb_input pt-3" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="unit_quantity_text">Unit Quantity</p>
            <div class="counter">
              <mat-icon
                class="counter-icon"
                (click)="reduceQuantity(location.columnId)"
                >remove</mat-icon
              >
              <input
                type="text"
                class="quantity-input white_bg"
                min="0"
                [max]="getAvailableQuantityByIndex()"
                [value]="location.quantityQuoted"
                [disabled]="true"
              />
              <mat-icon
                class="counter-icon"
                #matTool="matTooltip"
                matTooltip="All units are assigned"
                [matTooltipDisabled]="getAvailableQuantityByIndex() !== 0"
                (click)="addQuantity(location.columnId)"
                (click)="matTool.toggle()"
                >add</mat-icon
              >
            </div>
          </div>
         
        </div>
      </div>

      <div *ngIf="(MobileEditOrderScreen != 'editOrderChangeQuantity' ||  screenName != 'editOrder') && urlSegments">
        <mat-icon class="more_option" (click)="onView(location.columnId)"
          >more_vert</mat-icon
        >
      </div>
    </div>
  </div>
  <!-----Show below for change quantity--->
  <div class="size_scroll mt-3" *ngIf="(MobileEditOrderScreen === 'editOrderChangeQuantity' ||  screenName === 'editOrder') && !urlSegments">
    <div
      class="new_location p-3 mt-2"
      *ngFor="let location of newLocationValues "
    >
      <div class="site_details">
        <div class="map_icon_wrapper" fxLayoutAlign="center center">
          <img
            src="../../../../assets/img/icons/map.svg"
            alt="Location pin"
          />
        </div>

        <div class="px-2">
          <div class="site_name">
            Location name: {{ location?.address?.siteName }}
          </div>

          <div class="text_blue">
            Placement Note: {{ location?.address?.instructions }}
          </div>
       
          <div *ngIf="urlSegments" class="tb_input pt-3" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="unit_quantity_text">Unit Quantity</p>
            <div class="counter">
              <mat-icon
                class="counter-icon"
                (click)="reduceQuantity(location.columnId)"
                >remove</mat-icon
              >
              <input
                type="text"
                class="quantity-input white_bg"
                min="0"
                [max]="getAvailableQuantityByIndex()"
                [value]="location.quantityQuoted"
                [disabled]="true"
              />
              <mat-icon
                class="counter-icon"
                #matTool="matTooltip"
                matTooltip="All units are assigned"
                [matTooltipDisabled]="getAvailableQuantityByIndex() !== 0"
                (click)="addQuantity(location.columnId)"
                (click)="matTool.toggle()"
                >add</mat-icon
              >
            </div>
          </div>
          <!----When screen is edit order, show the below div---->
          <div *ngIf="(MobileEditOrderScreen === 'editOrderChangeQuantity' ||  screenName === 'editOrder') && !urlSegments" class="tb_input pt-3" fxLayoutAlign="start center" fxLayoutGap="10px">
            <p class="unit_quantity_text">Unit Quantity</p>
            <div class="counter">
              <mat-icon
                class="counter-icon"
                (click)="onFocusMinus(location); "
                >remove</mat-icon
              >
              <input
                type="text"
                class="quantity-input white_bg"
                [value]="location.quantityQuoted"
                [disabled]="true"
              />
              <mat-icon
                class="counter-icon"
                (click)="onFocusPlus(location); "
                >add</mat-icon
              >
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="(MobileEditOrderScreen != 'editOrderChangeQuantity' ||  screenName != 'editOrder') && urlSegments">
        <mat-icon class="more_option" (click)="onView(location.columnId)"
          >more_vert</mat-icon
        >
      </div>
    </div>
  </div>
</section>
