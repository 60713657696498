import { Routes } from "@angular/router";
import { MyUSSWrapperComponent } from "./components/myuss-wrapper/myuss-wrapper.component";
import { isRegisteredGuard } from "src/app/guards/is-registered.guard";
import { myussEnabledGuard } from "src/app/guards/myussEnabled/myuss-enabled.guard";

import { sysadminGuard } from "src/app/guards/sysadmin.guard";
import { featureGuard } from "src/app/guards/feature/feature.guard";
import { authGuard } from "src/app/guards/auth/auth.guard";
import { roleGuard } from "src/app/guards/role/role.guard";
import { inject } from '@angular/core';
import { ConfigService } from 'src/app/shared/services/config/config.service';


export const myUSSRoutes: Routes = [
  {
    path: "",
    component: MyUSSWrapperComponent,
    canActivate: [authGuard, myussEnabledGuard], 
    // canActivate: [authGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "home",
      },
      {
        path: "home",
        canActivate: [isRegisteredGuard],
        data: { feature: "myussHomeEnabled",},
        loadChildren: () =>
          import("../home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "quotes",
        canActivate: [isRegisteredGuard, featureGuard],
        data: { feature: "myussQuotesEnabled" ,Breadcrumb: 'Quotes'
        },
        loadChildren: () =>
          import("../quotes/quotes.module").then((m) => m.QuotesModule),
      },
      {
        path: "orders",
        canActivate: [isRegisteredGuard, featureGuard],
        data: { feature: "myussOrdersEnabled" , Breadcrumb: "Order List "},
        loadChildren: () =>
          import("../orders/orders.module").then((m) => m.OrdersModule),
      },
      {
        path: "billing",
        canActivate: [isRegisteredGuard, featureGuard],
        data: { feature: "myussBillingEnabled" },
        loadChildren: () =>
          import("../billing/billing.module").then((m) => m.BillingModule),
      },
      {
        path: "easypay",
        canActivate: [isRegisteredGuard,featureGuard],
        data: { feature: "myussEasyPayEnabled",Breadcrumb: 'Easy Pay'
        },
        loadChildren: () =>
          import("../easy-pay/easy-pay.module").then((m) => m.EasyPayModule),
      },
      {
        path: "cases",
        canActivate: [isRegisteredGuard,featureGuard],
        data: { feature: "myussCasesEnabled",Breadcrumb: 'Cases List'},
        loadChildren: () =>
          import("../cases/cases.module").then((m) => m.CasesModule),
      },
      {
        path: "support",
        canActivate: [isRegisteredGuard],
        data: {Breadcrumb: 'Technical Support'},
        // component: TechnicalSupportListComponent,
        loadChildren: () =>
          import("../technical-support/technical-support.module").then((m) => m.TechnicalSupportModule),
      },
      {
        path: "account",
        canActivate: [isRegisteredGuard],
        data: {Breadcrumb: 'Account'},
        loadChildren: () =>
          import("../accounts/accounts.module").then((m) => m.AccountsModule),
      },
      {
        path: "sysadmin",
        canActivate: [sysadminGuard],
        loadChildren: () =>
          import("../sysadmin/sysadmin.module").then((m) => m.SysadminModule),
      },
      {
        path: "users",
        canActivate: [roleGuard],
        data:{role: ["Account Owner", "Account Admin"],Breadcrumb: 'Users List'
        },
        loadChildren: () =>
          import("../users/users.module").then((m) => m.UsersModule),
      },
      {
        path:"scan",
        data: { role: ["Account Owner", "Account Admin","Standard User"]},
        loadChildren: () =>import("../scan/scan.module").then((m) => m.ScanModule),
      },
      {
        path:"projects",
        canActivate: [isRegisteredGuard,featureGuard],
        data: { feature: "myussProjectsEnabled", Breadcrumb: "Project List "},
        loadChildren: () =>import("../projects/projects.module").then((m) => m.ProjectsModule),
      },
      {
        path:"about-app",
        loadChildren: () =>import("../about-app/about-app.module").then((m) => m.AboutAppModule),
      },
      {
        path: "quotes/v2",
        canActivate: [isRegisteredGuard, featureGuard],
        data: { feature: "myussQuotesEnabled",Breadcrumb: 'Quote List'
        },
        loadChildren: () =>
          import("../enhance-quotes/enhance-quotes.routes").then((m) => m.enhanceQuotesRoutes),
      },
    ],
  },
  {
    path: "onboarding",
    canActivate: [authGuard,isRegisteredGuard],
    data: {Breadcrumb: 'Onboarding'},
    canMatch: [() => isNewOnboardingFlowEnabled()],
    loadChildren: () =>
      import('../onboarding-flow/accounts.routes').then((m) => m.accountsRoutes),
  }
];


function isNewOnboardingFlowEnabled(): boolean {
  const configService = inject(ConfigService);
  const flag = configService.getConfigProperty('NEW_ONBOARDING_FLOW');
  if (flag === undefined || flag === null) {
    return false
  }
  return flag;
}

