<section class="popup-wrapper">
  <div class="popup-header px-4 py-3">
    @if(record.type == 'case'){
    <p>Case Request Initiated</p>

    }
    @if(record.type == 'MySiteServices'){
      <p>Technical Request Initiated</p>
    }
    @if(record.type == 'BillingSupport'){
      <p>Billing Request Initiated</p>
    }

    <span>
      <mat-icon class="close-icon" (click)="close('close')"
        >close</mat-icon
      ></span
    >
  </div>
  <div class="upload-content">
    <div class="popup-body px-3 pb-4 pt-3">
      <ul class="px-4">
        @if((record.type == 'case' || 'BillingSupport') && record.type != 'MySiteServices'){
        <li>Your request has been sent to the Order Support team</li>

        } @if(record.type == 'MySiteServices'){
        <li>Your request has been sent to the IT Support team</li>

        }

        <li>
          A representative will coordinate the fulfillment of your request
        </li>
        <li>They will reach out if any additional information is required</li>
        @if(record.type == 'case'){
        <li>
          You can track the status and communicate through the Cases screen
        </li>

        } @if(record.type == 'BillingSupport'){
        <li>
          You can track the status and communicate through the Billing Support
          screen
        </li>
        } @if(record.type == 'MySiteServices'){
        <li>
          You can track the status and communicate through the Technical Support
          screen
        </li>

        }
      </ul>
    </div>
    <app-upload-dialog [record]="record"></app-upload-dialog>
  </div>
  <div class="popup-btns px-2 pb-2">
    <button mat-raised-button color="primary" (click)="close('ok')">OK</button>
  </div>
</section>
