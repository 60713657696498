import { inject } from "@angular/core";
import { ActivatedRoute, CanActivateFn, Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import { ConfigService } from "../shared/services/config/config.service";
import { ProfileService } from "../features/accounts/services/profile/profile-services.service";
import { AuthenticationService } from "../shared/services/authentication/authentication.service";

export const isRegisteredGuard: CanActivateFn = async (route, state) => {
  const router: Router = inject(Router);
  const profileService = inject(ProfileService);
  const configService = inject(ConfigService);
  const authService = inject(AuthenticationService);
  let userIsRegistered = await profileService.userIsRegistered();
const onboardingFlag = configService.getConfigProperty('NEW_ONBOARDING_FLOW');

if (!(await authService.isAuthenticated())) {
  return false;
}
if (state.url == "/account/create" || state.url =="/onboarding/create"  && !userIsRegistered) {
  await profileService.getUserProfileValue("accountId");
  userIsRegistered = await profileService.userIsRegistered();
}

console.log("Checking if user is registered", userIsRegistered);
  if (userIsRegistered) {
    if(onboardingFlag){

      if (state.url == "/onboarding/create" && profileService.selectedAccount().myussUserRole  && !localStorage.getItem("signUpRequestId")) {
        return router.navigate(["home"]);
      }
      return (true);

    }else{

      if (state.url == "/account/create" && profileService.selectedAccount().myussUserRole  && !localStorage.getItem("signUpRequestId")) {
        return router.navigate(["home"]);
      }
      return (true);

    }

  } else {
     if(onboardingFlag){
      if (state.url !== "/onboarding/create") {
        return router.navigate(["onboarding/create"]);
      }else{
        return true;
      }
     }else{
      if (state.url !== "/account/create") {
        return router.navigate(["account/create"]);
      } else {
        return (true);
      }
     }
  }
};


