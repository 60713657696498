import { PushNotificationService } from "src/app/features/myuss/services/push-notification/push-notification.service";
import { Component, effect } from "@angular/core";
import { AppNotifications } from "src/app/models/notification-model";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { Router } from "@angular/router";
import { notificationConfig } from "../notification-config";
import { ConfigService } from "src/app/shared/services/config/config.service";

@Component({
  selector: "app-push-notification",
  templateUrl: "./push-notification.component.html",
  styleUrls: ["./push-notification.component.scss"],
})
export class PushNotificationComponent {
  notificationList: AppNotifications[];
  selectedAccountId: string | undefined;
  notificationConfig = notificationConfig; 
  disableNotificationSetting: boolean;


  constructor(
    private pushNotificationService: PushNotificationService,
    private toaster: ToastrService,
    private profileService: ProfileService,
    private router: Router,
    private configService: ConfigService,
  ) {
    effect(() => {
      this.selectedAccountId = this.profileService.selectedAccount().accountId;
      this.getPushNotifications();
    });
  }

  ngOnInit() {
    this.disableNotificationSetting = this.configService.getConfigProperty("NOTIFICATION_SETTING");
  }

  getPushNotifications() {
    this.pushNotificationService
      .getPushNotifications(this.selectedAccountId)
      .subscribe({
        next: (res) => {
          if (res) {
            this.notificationList = res.sort((a, b) => {
              return (
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime()
              );
            });
          }
        },
        error: (error) => {
          this.toaster.error("Error fetching notifications:", error);
        },
      });
  }

  navigateTo(notification: AppNotifications): void {
    const config = notificationConfig[notification.notificationType];
    if (config && config.navigateTo) {
      const path = config.navigateTo(notification);
      this.router.navigateByUrl(path);
    } else {
      console.log('No navigation configured for this notification type');
    }
  }

  navigateToNotificationSetting(){
    this.router.navigate(['home/notification-setting']);
  }
}

