import { RFQProduct } from "./rfq-model";



export class SignupModel {
  requestId?:string;
  firstName: string;
  lastName: string;
  primaryPhoneNumber: string;
  mobilePhoneNumber: string;
  customerType: string;
  businessType: string;
  emailId: string | undefined;
  auth0Id: string;
  accountName?: string;
 }

export class UserOnboarding extends SignupModel {
  id?: string;
  onBoardingStatus: string;
  userLicenseAgreement?: boolean;
  termsAndCondition?: boolean;
  createdAt: string;
  subcollections: {
    singleAccounts: PendingApproval[];
    mutlipleAccounts: MultiAccount[];
  };

}

export class PendingApproval {
  id?: string;
  accountNumber: string;
  approvalStatus: string;
  accountName: string;
}
export class MultiAccount {
  id?: string;
  status: string;
  caseId: string;
  message: string;
}

export interface CasePayload {
  auth0Id: string;
  accountNumbers: string[];
  type: string;
  accountName: string;
}

export class UpdateRFQInFireStore{
   rfqId: string;
    myUssRFQStatus: string;
    products?:RFQProduct[]
}
