import { AppNotifications } from "src/app/models/notification-model";

export const notificationConfig = {
    commentAdded: {
      icon: 'notifications',
      title: 'New Comment',
      navigateTo: (notification:AppNotifications) => `cases/case-details/${notification.objectId}`,
    },
    statusChange: {
      icon: 'autorenew',
      title: 'Status Update',
      navigateTo: (notification:AppNotifications) => `cases/case-details/${notification.objectId}`,
    },
    "delivery/pickUp": {
      icon: 'autorenew',
      title: 'Status Update',
      navigateTo: (notification:AppNotifications) => `orders/ordersummary/${notification.objectId}`,
    },
  };
  