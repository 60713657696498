
import { Component, effect, HostListener, Input, Output,EventEmitter } from "@angular/core";
import { OrderService } from "src/app/features/orders/services/order.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { ScanService } from "src/app/features/scan/services/scan.service";
import {
  Bundle,
  ColumnData,
  EditOrderState,
  OrderModel,
} from "src/app/models/order-model";
import {
  QuoteLineGrouped,
  QuoteLineModel,
  QuoteModel,
} from "src/app/models/quote-line-model";
import { ConfigService } from "../../services/config/config.service";
import { firstValueFrom, Subscription } from "rxjs";

import { RejectpopupComponent } from "../dialogbox/reject-popup/reject-popup.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { serviceFrequencyMap } from "src/assets/config/constants";
import { PickupReason } from "src/assets/config/pickup-reason-list";
import { CaseModel, CaseProductInfo } from "src/app/models/case-details-model";
import { CalenderPopupComponent } from "src/app/features/orders/components/calender-popup/calender-popup.component";
import { GoogleMapsService } from "../../services/google/google-maps.service";
import {
  descriptionTableHeader,
  timeZoneMapper,
} from "src/assets/config/constants";
import { DatePipe } from "@angular/common";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { OrderChangeService } from "src/app/features/orders/services/order-change.service";
import { CaseDetailsStateService } from "src/app/features/cases/services/case-details-state.service";
import { CaseSuccessComponent } from "../dialogbox/case-success/case-success.component";
import { ToastrService } from "ngx-toastr";


@Component({
  selector: "app-location-manager-quote-summary",
  templateUrl: "./location-manager-quote-summary.component.html",
  styleUrls: ["./location-manager-quote-summary.component.scss"],
})
export class LocationManagerQuoteSummaryComponent {
  @Input() screenName: string; // Input property to receive screen name
  @Output() isSingleQuantity = new EventEmitter<boolean>();
  bundleData: QuoteLineGrouped[] = [];
  isMobile: boolean;
  EditOrderScreen: string = "editOrderChangeQuantity";
  showQuantityChangeDrawer = false;
  orderData: OrderModel | null;
  createCaseChangeQuantity: boolean;
  subscription: Subscription;
  productDetailsData: EditOrderState[] = [];
  bundle: QuoteLineModel[];
  uniqueBundleArray: Bundle[];
  location: ColumnData;
  bundleDataArr: Bundle;
  isReduceQuantity: boolean = false;
  isEditedOrder: boolean = false;
  flagForSingleQuantity: boolean = false;
  totalUnitQuantity:number = 0;
  
  public serviceFrequencyMap=serviceFrequencyMap;
 
  isEdited: boolean;
 private isSingleUnitWeb: boolean = false;
 pipe = new DatePipe("en-US");
 

  constructor(
    public quoteStateService: QuoteStateService,
    private orderService: OrderService,
    private scannerService: ScanService,
    private configService: ConfigService,
    public dialog: MatDialog,
    public router: Router,
    private scanService: ScanService, 
    private box: MatDialog,
    public gMapService: GoogleMapsService,
    private profileService: ProfileService,
    private orderChangeService: OrderChangeService,
    private caseService: CaseDetailsStateService,
    public toastr: ToastrService,
  ) {
    effect(() => {
      this.bundleData = this.quoteStateService.multiLocationData();
     
    });
  }

  ngOnInit() {
    if (window.innerWidth <= 600) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
     
    }
   
//This is for edit order web view - to get the data from order summary (parent component) to update the state for change quantity drawer data
    if(this.screenName === "editOrder"){
    
      this.createCaseChangeQuantity = this.configService.getConfigProperty(
        "EDIT_ORDER_CHANGE_QUANTITY_CASE"
      );
      this.subscription = this.orderService.orderSummary.subscribe((result) => {
        if (result) {
          this.orderData = result;
          this.productDetailsData = this.orderData?.productDetails;
          this.bundle = this.quoteStateService.prepareBundleData(
            this.orderData.quoteModel.quoteLines
          );

        
  
          if (this.orderData.quoteModel && this.orderData?.assetLocations) {
            let filteredJobSites = this.orderData.quoteModel.jobSites.filter(
              (site) =>
                this.orderData?.assetLocations.some(
                  (location) => site.address.id === location.jobsiteId
                )
            );
  
            // Assign the filtered jobsites back to quoteModel
          this.orderData.quoteModel.jobSites = filteredJobSites;
          this.totalUnitQuantity = filteredJobSites.reduce((sum, site) => sum + site.quantityQuoted, 0);
          }
  
          this.quoteStateService.mapJobitesToBundleData(
            this.orderData.quoteModel
          );
          this.emitSingleQuantityEvent();
         
          
          this.editOrderData();
        } else {
          const urlSegments = this.router.url.split("/");
          const lastId = urlSegments[urlSegments.length - 2];
         
          this.orderService.getOrderById(lastId);
        }
      });
  
      // }

    }

   
  }
  // Setting flag which is coming from child - location assignment component for cancelling order if quantity is single.
  receiveSingleQuantityMobile(flag:boolean){
 
    this.flagForSingleQuantity = flag;

  }

  emitSingleQuantityEvent() {
    
    if(this.bundle?.length == 1 && this.bundle[0]?.quantity == 1 && !this.isMobile){
      this.isSingleUnitWeb = !this.isSingleUnitWeb;
      this.isSingleQuantity.emit(this.isSingleUnitWeb);

    }
    
  } 

  //When user come from mobile responsive screen (now location assignment is child of this component) - to update state for change quantity drawer data

  toggleQuantityUI() {
    if (this.location.isEdited) {
      this.orderService.setDrawerState(false);
      let drawerCurrentState = this.orderService.drawerState();

      const dialog = this.dialog
        .open(RejectpopupComponent, {
          width: "430px",
          panelClass: "limit-exceed-dialog",
          data: {
            message:
              "Previous request for change quantity is in progress. Do you want to request a new change?",
            drawerStatus: drawerCurrentState,
          },
        })
        .afterClosed()
        .subscribe((result) => {
          if (result === "yes") {
            this.orderService.setDrawerState(!this.orderService.drawerState());
          }
        });
    } else {
      this.orderService.setDrawerState(!this.orderService.drawerState());

      // this.showQuantityChangeDrawer = !this.showQuantityChangeDrawer;
    }
  }

  selectUnit(index) {
    this.bundleData.forEach((item: QuoteLineGrouped) => {
      if (item.isSelected) {
        item.isSelected = false;
      }
    });
    const bundleDataNew:QuoteLineGrouped[]=[];
    Object.assign(bundleDataNew,this.bundleData)
    bundleDataNew[index].isSelected = true;
    
    this.quoteStateService.multiLocationData.set(bundleDataNew);
  }

  getAvailableQuantityByIndex(index: number) {
    const bundle = this.bundleData[index];
    if (bundle == undefined) return 0;
    const jobSiteIds = Object.keys(bundle).filter((key) =>
      key.includes("newLocation")
    );
    const total = jobSiteIds.reduce((totalQuantityQuoted, jobSiteId) => {
      return totalQuantityQuoted + bundle[jobSiteId].quantityQuoted;
    }, 0);
    return bundle.quantity - total;
  }
  //When user come from mobile responsive screen - to update state for change quantity drawer data
  onFocusGetEditOrderData() {
    if(this.flagForSingleQuantity && this.orderService.isReduceQuantityState()){
      //Copy pickup all function from edit order
   
      this.pickupAll();

    }
    else{
      this.isEditedOrder = this.location.isEdited;
    const contractId = this.orderData?.contractId;
    this.orderService.updateEditOrderValues({
      ...this.orderService.getCurrentEditOrderState(),
      selectedSite: this.location.address,
      contractId: contractId,

      sitewiseSelectedBundle: this.location.productDetails,
      // bundlesData: this.uniqueBundleArray,
      siteDetails: this.location.address,
      orderName: this.orderData?.quoteName,
      siteContact: this.location.contact,
      quantityQuoted: this.location.quantityQuoted,
      startDate: this.orderData?.startDate,
      endDate: this.orderData?.endDate,
      isEdited: this.isEditedOrder,
      totalUnitQuantity: this.totalUnitQuantity
    });

    this.toggleQuantityUI();

    }
    
  }

  //When user come from mobile responsive screen - to update state for change quantity drawer data
  handleDrawerDataInLocation(e: ColumnData) {
    this.location = e;
    this.onFocusGetEditOrderData();
  }
  editOrderData() {
   
    this.isEdited = this.location?.isEdited;
    const contractId = this.orderData?.contractId;
    this.orderService.updateEditOrderValues({
      ...this.orderService.getCurrentEditOrderState(),
      selectedSite: this.location?.address,
      contractId: contractId,

      sitewiseSelectedBundle: this.location?.productDetails,
      // bundlesData: this.uniqueBundleArray,
      siteDetails: this.location?.address,
      orderName: this.orderData?.quoteName,
      siteContact: this.location?.contact,
      quantityQuoted: this.location?.quantityQuoted,
      startDate: this.orderData?.startDate,
      endDate: this.orderData?.endDate,
      isEdited: this.isEdited,
    });

   
  }
  pickupAll() {
    const cancelOrder = this.box.open(CalenderPopupComponent, {
      minWidth: "20%",
      disableClose: true,
      data: {
        contractId: this.orderData?.contractId,
        endDate: "",
        isShowCloseBtn: true,
        showCancelationNote: true,
        stopNavigation: true,
      },
    });
    cancelOrder
      .afterClosed()
      .subscribe(
        async (res: {
          endDate: string;
          pickupReason: PickupReason;
          note: string;
        }) => {
          if (res.endDate) {
            const reasonForCaseCreation = [
              "Service quality",
              "Pricing issue",
              "Billing issue",
            ];
            if (
              this.createCaseChangeQuantity ||
              reasonForCaseCreation.includes(res.pickupReason.name)
            ) {
             
              const descriptionData: CaseProductInfo[] = [];
            
              this.productDetailsData.forEach((productDetails) => {
                const bundleData = productDetails.bundles.map((bundle) => {
                  const caseDescriptionData = new CaseProductInfo();
                  caseDescriptionData.Product = bundle.assetName;
                  caseDescriptionData.Size = "";
                  caseDescriptionData.Quantity = bundle.quantity;
                  caseDescriptionData["Service Frequency"] = bundle.serviceName;
                  caseDescriptionData.AncillaryServices =
                    bundle.ancillaryServiceList;
                  caseDescriptionData.Price = 0;
                  caseDescriptionData.Action = "Change Quantity Reduce Units";
                  caseDescriptionData.Notes = "";
                  return caseDescriptionData;
                });
               
                descriptionData.push(...bundleData);
              });
              const requestData: CaseModel =
                await this.requestBodyMapperCreateCase(
                  "Change Quantity - Reduce Units",
                  descriptionData,
                  this.orderData?.siteDetails.address,
                  this.getUnitNumbers(this.productDetailsData),
                  res?.note,
                  res?.pickupReason?.value,
                  res?.endDate
                );
              this.createCase(requestData);
            }
          }
        }
      );
  }
  async requestBodyMapperCreateCase(
    requestType:
      | "Move"
      | "Add New Unit Type"
      | "Change Service Frequency"
      | "Change Quantity - Add Units"
      | "Change Quantity - Reduce Units",
    requestData: CaseProductInfo[],
    siteAddress: string = "",
    units: string[] = [],
    placementNote: string = "",
    pickupReason: string = "",
    endDate: string
  ): Promise<CaseModel> {
 
    const data: CaseModel = new CaseModel();

    data.caseSubType = "Quantity/Frequency Change";
    data.siteAddress = siteAddress;

    data.accessNotes = "";
    data.contactId = this.profileService.selectedAccount().contactId as string;
    data.accountId = this.profileService.selectedAccount().accountId as string;
    data.selectedDate = endDate || "";
    data.placementNote = pickupReason
      ? `${placementNote} | Pickup Reason - ${pickupReason}`
      : placementNote;

    data.siteCityName = `${this.orderData?.siteDetails.city}`;
    data.siteContactName = `${this.orderData?.siteDetails.firstName} ${this.orderData?.siteDetails.lastName}`;
    data.siteContactPhone = `${this.orderData?.siteDetails.phone}`;
    data.siteStateName = `${this.orderData?.siteDetails.state}`;
    data.orderName = `${this.orderData?.quoteName?.slice(2)}`;
    data.zipcode = `${this.orderData?.siteDetails.zipcode}`;
    data.unitNumbers = units;

    const description = { Details: descriptionTableHeader, Data: requestData };
    data.productInfo = JSON.stringify(description);
    data.myussCaseType = requestType;

    // create the summary for the case
    const effectiveDateFormattedForNotes = this.pipe.transform(endDate, "MM/dd/yyyy") || "";
    data.summary = this.orderChangeService.formatCaseSummary(
      requestType,
      data,
      effectiveDateFormattedForNotes,
      this.location?.address,
      "**REVIEW ONLY**",
      true
    );
    // this.orderChangeService.formatCaseSummary(changeType, data, effectiveDateFormattedForNotes)

    const timeZoneResponse = await firstValueFrom(
      this.gMapService.getTimeZone(
        this.orderData?.siteDetails.latitude || 0,
        this.orderData?.siteDetails.longitude || 0
      )
    );

    data.caseRegion = timeZoneMapper[timeZoneResponse.timeZoneName];

    return data;
  }
  getUnitNumbers(productDetails: EditOrderState[]) {
    const unitNumbers: string[] = [];

    productDetails.forEach((detail) => {
      detail.bundles.forEach((bundle) => {
        unitNumbers.push(bundle.unitNumber);
      });
    });

    return unitNumbers;
  }
  createCase(data: CaseModel) {
  

    this.caseService
      .createCase(data, `${this.profileService.selectedAccount().accountId}`)
      .subscribe((res) => {
        if (res) {
          const box = this.dialog
            .open(CaseSuccessComponent, {
              panelClass: "case-success-dialog",
              disableClose: true,
              data: {
                id: res.data?.caseId,
                type: "case",
              },
            })
            .afterClosed()
            .subscribe((resPopup) => {
              if (resPopup === "ok") {
                this.router.navigate(["orders/case-details", res.data?.caseId]);
              }
            });
        } else {
          this.toastr.error("Failed to change the quantity");
        }
      });
  }
  @HostListener("window:resize") onWindowResize(): void {
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }
}
