import { Injectable, signal } from "@angular/core";
import { catchError, firstValueFrom, map, Subject, take } from "rxjs";
import { IdToken } from "@auth0/auth0-angular";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";
import { MatDialog } from "@angular/material/dialog";
import { CommonWarningPopupComponent } from "../../../../shared/components/common-warning-popup/common-warning-popup.component";
import { ToastrService } from "ngx-toastr";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { UserProfile } from "src/app/models/profile-model";
import { CasePayload, SignupModel, UpdateRFQInFireStore, UserOnboarding } from "src/app/models/onboarding.model";
import { ApiRespDTO } from "src/app/models/api.model";
import { UssApiService } from "src/app/features/myuss/services/uss-api/uss-api.service";
import { HelperFunctionService } from "src/app/theme/utils/helper-function.service";
import { HttpParams } from "@angular/common/http";
import { RFQ } from "src/app/models/rfq-model";

@Injectable({
  providedIn: "root",
})
export class AccountsService {
  idTokenClaims: IdToken | undefined;
  constructor(
    private api: UssApiService,
    private auth: AuthenticationService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private utilsService: HelperFunctionService,
    private profileService: ProfileService
  ) {
    this.getTokenClaims();
  }
  stepperList = signal([
    {
      title: " Email Verified",
      isSelected: true,
      icon: "email-verified.svg",
      isCompleted: false,
      isDisable: false,
    },
    {
      title: "Your User Profile",
      isSelected: false,
      icon: "your-user-profile.svg",
      isCompleted: false,
      isDisable: false,
    },
    {
      title: "Link Account",
      isSelected: false,
      icon: "link-account.svg",
      isCompleted: false,
      isDisable: false,
    },
    {
      title: "New Account",
      isSelected: false,
      icon: "new-account.svg",
      isCompleted: false,
      isDisable: false,
    },
    {
      title: "Your Dashboard",
      isSelected: false,
      icon: "your-dashboard.svg",
      isCompleted: false,
      isDisable: false,
    },
  ]);
  rfqDetails = signal<RFQ>(new RFQ)
  emailVerification = signal<boolean>(false);
  userProfile = signal<UserProfile | null>(null);
  selectedStepperIndex = signal<number>(0);
  onboardingSignal = signal<UserOnboarding>(new UserOnboarding());
  statusMap = {
    single: 2,
    multiple: 2,
  };
  intervalStop$ = new Subject<void>();
  accounts = signal<string[]>([]);

  public async requestEmailConfirmation(): Promise<boolean> {
    return firstValueFrom(
      this.api.post("users/me/request-email-verification", {}).pipe(
        take(1),
        map((res: ApiRespDTO) => {
          if (res["status"] === 1000) {
            return true;
          }
          return false;
        }),
        catchError((err) => {
          console.error(`requestEmailConfirmation ${JSON.stringify(err)}`);
          throw err;
        })
      )
    );
  }

  /**
   * Fetchs profile api
   */
  fetchProfile() {
    return this.api.get("users/me").pipe(
      take(1),
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          this.userProfile.set(res["data"]);
          this.emailVerification.set(res["data"].emailVerified);
          return res["data"];
        } else if (res["status"] === 1031) {
          const userProfile = res["data"] as UserProfile;
          this.emailVerification.set(res["data"].emailVerified);
          this.userProfile.set(userProfile);
          return res["data"];
        }

        return null;
      }),
      catchError((err) => {
        console.error(`error user profile: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  async updateStepper(
    index: number,
    isCompleted: boolean = true,
    isDisableIndex?: number
  ) {
    const list = this.stepperList().map((list, i) => {
      if(index==1 && this.emailVerification() && i==0){
        list.title='Email Verified';
      }
      if (i < index) {
        list.isCompleted = isCompleted;
        list.isSelected = false;
      } else {
        list.isCompleted = false;
        list.isSelected = false;
      }
      if (i == index) {
        list.isSelected = true;
      }
      if (isDisableIndex == i) {
        list.isDisable = true;
        list.isCompleted = false;
        list.isSelected = false;
      } else {
        list.isDisable = false;
      }
      return list;
    });
    this.stepperList.set(list);
  }
  /**
   * saves user onboarding Data
   * @param data
   * @returns
   */
  saveUserOnboardingData(data: Partial<UserOnboarding>) {
    return this.api.post("users/add-user-details", data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(
          `error saving user onboarding data: ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  fetchUserOnboardingData() {
    return this.api.get("users/fetch-user-details").pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          console.log("fetchUserOnboardingData", res["data"]);
          return res["data"];
        }
        return null;
      }),
      catchError((err) => {
        console.error(
          `error fetching user onboarding data: ${JSON.stringify(err)}`
        );
        throw err;
      })
    );
  }

  /**
   * set user onboarding state
   * @param state
   */
  setOnboardingSignal(state: UserOnboarding) {
    const currentState = this.onboardingSignal();
    const newOnboardingState = Object.assign({}, currentState, state);
    console.log("new State", newOnboardingState);
    this.onboardingSignal.set(newOnboardingState);
  }

  linkAccount(data: Record<string, string>) {
    return this.api.post("users/link-account", data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        localStorage.removeItem("linkAccountRequestId");
        localStorage.removeItem("linkAccountRequestBody");
        console.error(`error in link account: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  async getTokenClaims() {
    this.idTokenClaims = await this.auth.idTokenClaims();
  }

  createAccount(data: SignupModel) {
    return this.api.post("users/create-onboarding-user", data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000 || res["status"] === 1018) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        localStorage.removeItem("createNewRequestId");
        localStorage.removeItem("createNewRequestBody");
        console.error(`error create Account user: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  popUpConfirmation(status: string,) {
    const linkAccountReq = {
      auth0Id: this.idTokenClaims?.sub,
      accountName: this.onboardingSignal().accountName || "",
      accountNumber: "",
    };
    if (status == "single") {
      const dialog = this.dialog.open(CommonWarningPopupComponent, {
        minWidth: "35%", data: {
          headerBodyText:
            "A similar account name exists. You can either confirm your account name or link to the existing account.",
          leftButtonText: "Link to Account",
          rightButtonText: "Confirm Account Name",
        }
      });
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.onLinkAccount(linkAccountReq);
        }
      });
    }
    if (status == "multiple") {

      const dialog = this.dialog.open(CommonWarningPopupComponent, {
        minWidth: '35%',
        data: {
          headerIcon: "assets/img/reject-popup-alert.svg",
          headerIconAltText: "alert-triangle",
          headerBodyText:
            "Multiple records with the similar account names exist. You can confirm the current account name, enter a new name, or initiate a case for further assistance.",
          leftButtonText: "Initiate Case",
          rightButtonText: "Confirm Account Name",
        },
      });
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          if (status == "multiple") {
            const payload: CasePayload = {
              auth0Id: this.idTokenClaims?.sub,
              accountNumbers: this.accounts(),
              type: 'Duplicate Account',
              accountName: this.onboardingSignal().accountName || "",
            };
            this.initiateCase(payload).subscribe(
              (res) => {
                if (res) {
                  const onboardingState = new UserOnboarding();
                  onboardingState.onBoardingStatus = 'multiple';
                  onboardingState.accountName = this.onboardingSignal().accountName || "",
                  onboardingState.subcollections = {
                    singleAccounts:
                      this.onboardingSignal().subcollections?.singleAccounts || [],
                    mutlipleAccounts: [],
                  };
                  this.toastr.success('Case initiated successfully')
                  this.setOnboardingSignal(onboardingState);
                  this.updateStepper(2, true, 3);
                  this.selectedStepperIndex.set(2);
                  // this.onLinkAccount(linkAccountReq);
                }
              }
            );
          }
        }
      });
    }
  }

  onLinkAccount(
    linkAccountReq: Record<string, string>,
    isRefresh: boolean = false
  ) {
    if (isRefresh) {
      const callback = this.linkAccount(linkAccountReq);
      this.utilsService
        .startInterval(() => callback, 3000, this.intervalStop$)
        .subscribe((res) => {
          if (!res) {
            localStorage.removeItem("linkAccountRequestId");
            localStorage.removeItem("linkAccountRequestBody");
            this.intervalStop$.next();
            this.intervalStop$.complete();
            return;
          }

          const { status, data, message } = res;

          if (status === 1000) {
            this.linkAccountResHandler(res["data"]);
            localStorage.removeItem("linkAccountRequestId");
            localStorage.removeItem("linkAccountRequestBody");
            this.intervalStop$.next();
            this.intervalStop$.complete();
          }

          if (status === 1018) {
            this.utilsService.handleRefreshInfoMessage(message);
          }
        });
      return;
    }
    this.linkAccount(linkAccountReq).subscribe((res) => {
      if (res) {
        if (res.data.status.toLowerCase() === 'multiple') {
          const status = res.data.status.toLowerCase();
          this.accounts.set(res.data?.accountNumbers ?? []);
          const onboardingState = new UserOnboarding();
          onboardingState.accountName = linkAccountReq.accountName || "",
          this.setOnboardingSignal(onboardingState);
          this.popUpConfirmation(status);
        } else {
          this.linkAccountResHandler(res["data"]);
        }
      }
      localStorage.removeItem("linkAccountRequestId");
      localStorage.removeItem("linkAccountRequestBody");
    });
  }

  linkAccountResHandler(responseData: Record<string, string>) {
    if (responseData["status"]?.toLowerCase() === "not found") {
      this.toastr.error(
        "No account found matching the entered information. Please check your details and try again."
      );
      return;
    }
    this.selectedStepperIndex.set(2);
    const status = responseData.status?.toLowerCase();
    const onboardingState = new UserOnboarding();
    onboardingState.onBoardingStatus = status;
    if (status == "single") {
      onboardingState.subcollections = {
        singleAccounts: [
          {
            accountNumber: responseData.accountNumber,
            approvalStatus: responseData.status,
            accountName: responseData.accountName,
          },
        ],
        mutlipleAccounts:
          this.onboardingSignal().subcollections?.mutlipleAccounts || [],
      };
    }
    if (status == "multiple") {
      onboardingState.subcollections = {
        singleAccounts:
          this.onboardingSignal().subcollections?.singleAccounts || [],
        mutlipleAccounts: [],
      };
    }

    this.setOnboardingSignal(onboardingState);

    this.updateStepper(2, true, 3);
  }

  cancelRequest() {
    const account =
      this.onboardingSignal().subcollections?.singleAccounts || [];
    const req = account[account.length - 1];
    const cancelReq = {
      auth0Id: this.idTokenClaims?.sub,
      accountName: req.accountName || "",
      accountNumber: req.accountNumber,
    };
    return this.api.post("users/cancel-approval-request", cancelReq).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res["data"];
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error while canceling request: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  onCancel() {
    this.cancelRequest().subscribe((res) => {
      if (res) {
        this.retrieveOnboardingDetails();
      }
    });
  }

  retrieveOnboardingDetails() {
    this.fetchUserOnboardingData().subscribe((res) => {
      if (res) {
        this.setOnboardingSignal(res);
        console.log("state", this.onboardingSignal());
        this.selectedStepperIndex.set(
          this.statusMap[res?.onBoardingStatus?.toLowerCase()] || 0
        );
        if (this.statusMap[res?.onBoardingStatus?.toLowerCase()] == 2) {
          this.updateStepper(
            this.statusMap[res?.onBoardingStatus?.toLowerCase()] || 0,
            true,
            3
          );
        } else {
          const stepperListData = this.stepperList();
          const updateStepper = this.emailVerification() ? true : false
          stepperListData[0].title = updateStepper ? 'Email Verified' : 'Verify Your Email'
          stepperListData[0].isCompleted = updateStepper
          stepperListData[0].isSelected = !updateStepper
          this.stepperList.set(stepperListData)
        }
      }
    });
  }

  onSaveUserDetails(
    userOnboarding: Partial<UserOnboarding>,
    stepperIndex: number,
    disabledStepperIndex: number
  ) {
    return this.saveUserOnboardingData(userOnboarding).subscribe((res) => {
      if (res?.["data"]) {
        this.setOnboardingSignal(userOnboarding as UserOnboarding);
        this.selectedStepperIndex.set(stepperIndex);
        this.updateStepper(stepperIndex, true, disabledStepperIndex);
        return res;
      }
      return res;
    });
  }

  backToUserInfo() {
    this.selectedStepperIndex.set(1);
    this.updateStepper(1);
  }

  onCreateNewAccount(createNewReq: SignupModel, isRefresh: boolean = false) {
    if (isRefresh) {
      const callback = this.createAccount(createNewReq);
      this.utilsService
        .startInterval(() => callback, 3000, this.intervalStop$)
        .subscribe((res) => {
          if (!res) {
            localStorage.removeItem("createNewRequestId");
            localStorage.removeItem("createNewRequestBody");
            this.intervalStop$.next();
            this.intervalStop$.complete();
            return;
          }

          const { status, data, message } = res;

          if (status === 1000) {
            const creationStatus = data["status"]?.toLowerCase();
            if (creationStatus == "new user created") {
              this.fetchUserDetails();
            } else {
              this.popUpConfirmation(creationStatus);
            }
            localStorage.removeItem("createNewRequestId");
            localStorage.removeItem("createNewRequestBody");
            this.intervalStop$.next();
            this.intervalStop$.complete();
          }

          if (status === 1018) {
            this.utilsService.handleRefreshInfoMessage(message);
          }
        });
      return;
    }
    this.createAccount(createNewReq).subscribe((res) => {
      if (res) {
        this.setOnboardingSignal(createNewReq as UserOnboarding);
        const status = res?.["data"]["status"]?.toLowerCase();
        if (status == "new user created") {
          this.fetchUserDetails();
        } else {
          this.accounts.set(res.data?.accountNumbers ?? []);
          this.popUpConfirmation(status);
        }
      }
      localStorage.removeItem("createNewRequestId");
      localStorage.removeItem("createNewRequestBody");
    });
  }

  async fetchUserDetails() {
    await this.profileService.getInitialApplicationState(false);
    const users = this.profileService.userProfile$.value;
    const activeAccount = users.accounts?.filter(
      (account) => account.myussUserRole !== null
    );
    localStorage.setItem("accountId", `${activeAccount[0]?.accountId}`);
    this.profileService.selectedAccount.set(activeAccount[0]);
  }

  initiateCase(data: CasePayload) {
    return this.api.post("users/initiate-case", data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res["data"];
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error while request: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }


  updateRFQInFirestore(data : UpdateRFQInFireStore){
    return this.api.put("users/update-rfq-details",data).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res;
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error while request: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  fetchRFQFromFirestore(status : string){
    let queryParams = new HttpParams();
    queryParams = queryParams.append("status", status);
    return this.api.get("users/fetch-rfq-details",{
      params: queryParams,
    }).pipe(
      map((res: ApiRespDTO) => {
        if (res["status"] === 1000) {
          return res["data"];
        }
        return null;
      }),
      catchError((err) => {
        console.error(`error while request: ${JSON.stringify(err)}`);
        throw err;
      })
    );
  }

  resetRFQSignal(){
    this.rfqDetails.set(new RFQ)
  }

}
