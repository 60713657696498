<section class="wrapper">
  <div class="web-view border-radius-8 white_bg m-3 p-1">
    @if(mobileView && (endPoint === 'cases')){
    <mat-card>
      <mat-card-content class="screen_header">
        <div class="case-back-arrow">
          @if(endPoint === 'cases'){
          <mat-icon (click)="navigateBack()">keyboard_arrow_left</mat-icon>

          }

          <h3 class="case-title">{{ title }}</h3>
        </div>
        @if(screen == 'technical-support-list'){
        <div>
          <button class="get_support" mat-raised-button color="primary" (click)="getSupport()">
            Get Support
          </button>
        </div>

        } @if(screen == 'billing-support-list'){
        <div>
          <button class="get_support" mat-raised-button color="primary" (click)="onBillingSupport()">
            Get Support
          </button>
        </div>

        }
      </mat-card-content>
      @if(screen !== 'technical-support-list' && endPoint === 'cases'){
      <p class="technical-support-navigation">
        Note - To initiate request for technical support
        <a [routerLink]="['/support/technical-support']" target="_self">
          Click here</a>
        to initiate.
      </p>

      } @if(screen == 'technical-support-list'){
      <p class="technical-support-navigation">
        Note - For changes to existing orders, please use the
        <a [routerLink]="['/orders']" target="_self"> Order module</a>
      </p>
      }
    </mat-card>

    }
    @if(mobileView && screen == 'technical-support-list'){
      <mat-card>
        <mat-card-content class="screen_header">
          
          <h3 class="case-title">
          <mat-icon (click)="navigateBack()">keyboard_arrow_left</mat-icon>
          Technical Support {{title}}
        </h3>
            <p class="technical-support-navigation">
              Note - For changes to existing orders, please use the
              <a [routerLink]="['/orders']" target="_self"> Order module</a>
            </p>
        </mat-card-content>
      </mat-card>
    }
    @if(mobileView && screen == 'billing-support-list'){
      <mat-card>
        <mat-card-content class="screen_header">
            <h3 class="case-title">
          <mat-icon (click)="navigateBack()">keyboard_arrow_left</mat-icon>
          Billing Support {{title}}
        </h3>
        </mat-card-content>
      </mat-card>
    }

    <!-- case list for desktop view -->
    @if(!mobileView){
    <div class="mt-3">
      <div>
        <div class="cases-header px-3">
          <div class="child child-one">
            @if(screen === 'billing-support-list') {
              <h3 class="case-title">Billing Support {{title}}</h3>
            }
            @if( screen !== 'technical-support-list' && endPoint === 'cases'){
            <h3 class="case-title">{{ title }}</h3>
              <p class="technical-support-navigation">
                Note - To initiate request for technical support
                <a [routerLink]="['/support/technical-support']" target="_self">
                  Click here</a
                >
                to initiate.
              </p>
            } @if(screen == 'technical-support-list'){
              <h3 class="case-title">Technical Support {{title}}</h3>
            <p class="technical-support-navigation">
              Note - For changes to existing orders, please use the
              <a [routerLink]="['/orders']" target="_self"> Orders module </a>
            </p>

            }
          </div>

          <div class="child child-two">
            <mat-slide-toggle
              [aria-label]="isCaseClosed.toString()"
              color="primary"
              [checked]="isCaseClosed"
              (change)="onToggleChange($event)"
            >
              @if(screen != 'technical-support-list' && screen !=
              'billing-support-list'){
              <span> Show closed cases </span>

              } @if(screen == 'technical-support-list' || screen ==
              'billing-support-list'){
              <span> Show closed requests </span>

              }
            </mat-slide-toggle>
          </div>
        </div>
        <mat-divider></mat-divider>
        <div class="subheader_button mt-2 px-3">
          <div class="subheader">
            <div class="filter">
              <mat-form-field
                appearance="outline"
                class="search prefix-icon-position"
              >
                <mat-icon matPrefix>search</mat-icon>
                <input
                  matInput
                  (keyup)="applyFilter($event)"
                  placeholder="Search"
                  #searchInput
                />
              </mat-form-field>
            </div>
            @if(isCaseClosed){
            <div class="filter">
              <mat-form-field class="selector mt-2" appearance="outline">
                <mat-label>Duration</mat-label>
                <mat-select
                  [(ngModel)]="selectedOption"
                  (selectionChange)="selectedValue()"
                >
                  <mat-option value="30 days">1 Month</mat-option>
                  <mat-option value="60 days">2 Months</mat-option>
                  <mat-option value="90 days">3 Months</mat-option>
                  <mat-option value="Custom Date">Custom Date</mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            } @if(showFromToDate){
            <div class="filter">
              <mat-form-field
                class="example-full-width mt-2"
                appearance="outline"
              >
                <mat-label>From Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="fromDatePicker"
                  (dateChange)="selectedStartDate($event)"
                  [max]="maxDate"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="fromDatePicker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #fromDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            } @if(showFromToDate){
            <div class="filter">
              <mat-form-field
                class="example-full-width mt-2"
                appearance="outline"
              >
                <mat-label>To Date</mat-label>
                <input
                  matInput
                  [matDatepicker]="toDatePicker"
                  (dateChange)="selectedEndDate($event)"
                  [max]="maxDate"
                  [min]="minEndDate"
                />
                <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
                <mat-datepicker-toggle matIconSuffix [for]="toDatePicker">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #toDatePicker></mat-datepicker>
              </mat-form-field>
            </div>

            } @if(selectedOption == 'Custom Date'){
            <div>
              <button
                [disabled]="bothDateSelected()"
                mat-raised-button
                color="primary"
                class="mb-3"
                (click)="getCustomDateClosedCases()"
              >
                Search
              </button>
            </div>

            }
          </div>
          @if(screen == 'technical-support-list'){
          <div>
            <button
              class="get_support"
              mat-raised-button
              color="primary"
              (click)="getSupport()"
            >
              Get Support
            </button>
          </div>

          } @if(screen == 'billing-support-list'){
          <div>
            <button
              class="get_support"
              mat-raised-button
              color="primary"
              (click)="onBillingSupport()"
            >
              Get Support
            </button>
          </div>

          }
        </div>
      </div>
      @if(isCases || isOrderCases){

      <div class="px-3">
        <table mat-table [dataSource]="dataSource" matSort>
          <!-- Define columns -->
          <ng-container matColumnDef="caseNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              @if((screen == 'technical-support-list' || 'billing-support-list') && screen !== 'cases' && screen !== 'order-cases'){
              <span> SUPPORT NO. </span>

              } @else {
              <span> CASE NO. </span>

              }
            </th>
            <td mat-cell *matCellDef="let element">{{ element.caseNumber }}</td>
          </ng-container>
          <ng-container matColumnDef="subject">
            <th
              mat-header-cell
              *matHeaderCellDef
              mat-sort-header
              class="custom-header"
            >
              SUBJECT
            </th>
            <td
              mat-cell
              *matCellDef="let element"
              #nameTollTip="matTooltip"
              [matTooltip]="element.subject"
              (click)="nameTollTip.toggle()"
            >
              {{ element.subject ? (element.subject | truncate : 20) : "" }}
            </td>
          </ng-container>
          @if(!isOrderScreen){
          <ng-container matColumnDef="orderNumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>ORDER ID</th>
            <td mat-cell *matCellDef="let element">
              {{ getOrderNumber(element.orderNumber) }}
            </td>
          </ng-container>

          } @if(!isOrderScreen){
          <ng-container matColumnDef="siteAddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              SITE ADDRESS
            </th>
            <td mat-cell *matCellDef="let element">
              <ng-container
                *ngIf="
                  element.orderAddress?.street ||
                    element.orderAddress?.city ||
                    element.orderAddress?.state ||
                    element.orderAddress?.zipcode;
                  else blankTd
                "
              >
                {{ element.orderAddress?.street }}<br />
                {{ element.orderAddress?.city }},
                {{ element.orderAddress?.state }}
                {{ element.orderAddress?.zipcode }}
              </ng-container>
              <ng-template #blankTd>-</ng-template>
            </td>
          </ng-container>

          }

          <ng-container matColumnDef="createdDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              OPENING DATE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.createdDate | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lastModifiedDate">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              LAST UPDATED
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.lastModifiedDate | date : "MM/dd/yyyy" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="type">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              REQUEST TYPE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ getCaseType(element.type) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="myusscasetype">
            <th
              mat-header-cell
              *matHeaderCellDef
              class="custom-header"
              mat-sort-header
            >
              MYUSS CASE TYPE
            </th>
            <td mat-cell *matCellDef="let element">
              {{ element.myussCaseType }}
            </td>
          </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef class="custom-header" mat-sort-header>
              STATUS
            </th>
            <td mat-cell *matCellDef="let element">
              <div>
                @if(element?.status){
                  <div [ngClass]="getStatusClass(element.status)">
                    {{getStatusText(element.status)}}
                  </div>
                }
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="custom-header">
              ACTION
            </th>
            <td mat-cell *matCellDef="let element">
              <img
                src="../../../../assets/img/icons/visibility.png"
                class="visibility_css"
                (click)="viewCaseDetails(element.id)"
                alt="View Details"
              />
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="displayedColumns"
            class="custom-header"
          ></tr>

          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
        @if(isCases || isOrderCases){
        <mat-paginator
          [pageSizeOptions]="[10, 20]"
          showFirstLastButtons
          aria-label="Select page of cases"
        ></mat-paginator>

        }
      </div>

      } @if(!isCases && !isOrderScreen){
      <div class="mt-1" fxLayoutAlign="center">
        @if(screen != 'technical-support-list' && screen !=
        'billing-support-list'){
        <h2 class="no-cases">There are no cases related to the account.</h2>

        } @if(screen == 'technical-support-list' || screen == 'billing-support-list'){
        <h2 class="no-cases">There are no support request.</h2>

        }
      </div>

      } @if(!isOrderCases && isOrderScreen){

      <div class="mt-1" fxLayoutAlign="center">
        <h2 class="no-cases">There are no cases related to the order.</h2>
      </div>

      }
    </div>

    }
    <!-- for mobile view -->
    @if(mobileView !== false){
    <div class="px-3">
      <app-card-list-mobile-view
        [screen]="screen"
        [orderName]="orderName"
        [contractsFilter]="contractsFilter"
      ></app-card-list-mobile-view>
    </div>

    }
  </div>
</section>
