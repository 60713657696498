import { Injectable, effect } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { BehaviorSubject, map, catchError, firstValueFrom, Subscription } from "rxjs";
import {
  GetContactModel,
  ContactResponse,
} from "src/app/models/site-details-model";
import { LoadingService } from "src/app/shared/services/loading/loading.service";
import { UssApiService } from "src/app/features/myuss/services/uss-api/uss-api.service";
// import { Account } from "src/app/models/onboarding.model";
import { ProfileService } from "../profile/profile-services.service";
import { AddLocation, GetAddressModel } from "src/app/models/address-model";
import { HttpParams } from "@angular/common/http";
import { FormGroup, Validators } from "@angular/forms";
import { Account } from "src/app/models/account-model";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  public accounts$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  public selectedAccountId$: BehaviorSubject<string> =
    new BehaviorSubject<string>("");
  public selectedAccount$: BehaviorSubject<Account> =
    new BehaviorSubject<Account>(new Account());
  public selectedAccountContacts$: BehaviorSubject<GetContactModel[]> =
    new BehaviorSubject<GetContactModel[]>([]);

  public savedAddresses$: BehaviorSubject<GetAddressModel[]> =
    new BehaviorSubject<GetAddressModel[]>([]);

  constructor(
    private api: UssApiService,
    private loadingService: LoadingService,
    private toastr: ToastrService,
    private profileService: ProfileService
  ) {
    this.subscribeToAccountId();
    // this.subscribeToProfile();
    effect(() => {
      const account = this.profileService.selectedAccount()
      if(account.accountId){
        this.selectedAccountId$.next(
          `${account.accountId}`
        );
        this.fetchAddressForCurrentAccount("parent");
      }
    });
  }

  // update the Account's billing address
  async updateBillingAddress(account: Account) {
    // TODO: create billing address update DTO and use it here, if it's an existing address include the ID
    const resp = await firstValueFrom(
      this.api.put(`accounts/${account.id}/billing-address`, account)
    );
  }

  async fetchAddressForCurrentAccount(type: string): Promise<void> {
    await this.fetchAddressesByAccountId(this.selectedAccountId$.value, type);
  }
  /**
   * Fetchs saved address
   * @param id
   */
  async fetchAddressesByAccountId(id: string, type: string): Promise<void> {
    let queryParams = new HttpParams();
    // queryParams = queryParams.append("type", "parent");
    queryParams = queryParams.append("type", type);
    this.loadingService.setLoader(true);
    await firstValueFrom(
      this.api
        .get(`accounts/${id}/addresses`, {
          params: queryParams,
        })
        .pipe(
          map((res) => {
            if (res["message"] === "Success"  || res["status"] === 1000) {
              this.savedAddresses$.next(res["data"] as GetAddressModel[]);
            }
            this.loadingService.setLoader(false);
          }),
          catchError((err) => {
            console.error(`error getting addresses : ${JSON.stringify(err)}`);
            this.loadingService.setLoader(false);
            throw err;
          })
        )
    );
  }

  /**
   * Fetches the details for the selected account
   */
  async fetchSelectedAccountDetails() {
    const account = await this.getAccountDetails(this.selectedAccountId$.value);
    this.selectedAccount$.next(account);
  }

  async getAccountDetails(id: string): Promise<Account> {
    const account = (await firstValueFrom(
      this.api.get(`accounts/${id}`)
    )) as Account;

    console.log("getAccountDetails", account);
    return account;
  }

  // TODO: this doesn't need to be a separate method, just get contacts with the rest of the Account details
  getContactsForSelectedAccount() {
    this.fetchContacts().subscribe((res) => {
      if (res) {
        this.selectedAccountContacts$.next(res);
      }
    });
  }

  /**
   * Fetchs saved contacts for site details
   * @param id
   */
  fetchContacts() {
    return this.api
      .get(
        `accounts/${this.profileService.selectedAccount().accountId}/contacts`
      )
      .pipe(
        map((res: ContactResponse) => {
          if (res["status"] === 1000 || res["message"] === "Success" ) {
            this.selectedAccountContacts$.next(
              res["data"] as GetContactModel[]
            );
            return res["data"] as GetContactModel[];
          } else {
            this.toastr.error("Error fetching contacts");
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting contacts : ${JSON.stringify(err)}`);
          this.loadingService.setLoader(false);
          throw err;
          // return of(null);
        })
      );
  }

  // Helper methods for subscriptions below
  // private subscribeToProfile() {
  //   this.profileService.userProfile$.subscribe((profile) => {
  //     if (profile) {
  //       this.selectedAccountId$.next(profile.accountId);
  //     }
  //   });
  // }

  private subscribeToAccountId() {
    this.selectedAccountId$.subscribe((id) => {
      console.log("selectedAccountId$ changed", id);
      if (id) {
        this.fetchSelectedAccountDetails().then(() => {
          console.log("fetched account details");
        });
      }
    });
  }

  addLocation(data: AddLocation) {
    return this.api
      .post(`accounts/${this.selectedAccountId$.value}/addresses`, data)
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
          return null;
        }),
        catchError((err) => {
          console.error(`error getting address : ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }
  updateLocation(data: AddLocation) {
    return this.api
      .put(
        `accounts/${this.selectedAccountId$.value}/addresses/${data.address.id}`,
        data
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          }
          return null;
        }),
        catchError((err) => {
          console.error(`error getting address : ${JSON.stringify(err)}`);
          throw err;
        })
      );
  }

  fetchSiteLocation(addressId: string) {
    return this.api
      .get(
        `accounts/${
          this.profileService.selectedAccount().accountId
        }/addresses/${addressId}/subsites`
      )
      .pipe(
        map((res) => {
          if (res["status"] === 1000) {
            return res["data"];
          } else {
            this.toastr.error("Error fetching site");
            return null;
          }
        }),
        catchError((err) => {
          console.error(`error getting site : ${JSON.stringify(err)}`);
          this.loadingService.setLoader(false);
          throw err;
          // return of(null);
        })
      );
  }

  subscribeToAccount(purchaseOrderForm: FormGroup): Subscription {
    return this.selectedAccount$.subscribe((account) => {
      if (account && account.requirements?.purchaseOrder) {
        purchaseOrderForm?.controls.poNumber.setValidators([Validators.required]);
        purchaseOrderForm?.controls.poNumber.updateValueAndValidity();
      } else {
        purchaseOrderForm?.controls.poNumber.clearValidators();
        purchaseOrderForm?.controls.poNumber.updateValueAndValidity();
      }
    });
  }
}
