import { DatePipe } from "@angular/common";
import { Component, effect, Input } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subscription } from "rxjs";
import { ProfileService } from "src/app/features/accounts/services/profile/profile-services.service";
import { EnhanceQuoteService } from "src/app/features/enhance-quotes/services/enhance-quote.service";
import { FixedButtonService } from "src/app/features/quotes/services/fixed-button.service";
import { QuoteCreationService } from "src/app/features/quotes/services/quote-creation.service";
import { QuoteStateService } from "src/app/features/quotes/services/quote-state.service";
import { QuoteModelDetailsV2 } from "src/app/models/enhance-quote-model";
import {
  LocationItem,
  QuoteLineGrouped,
} from "src/app/models/quote-line-model";
import { QuoteState } from "src/app/models/quoteState-model";
import { RequestSiteDetails } from "src/app/models/site-details-model";

@Component({
  selector: "app-location-summary",
  templateUrl: "./location-summary.component.html",
  styleUrls: ["./location-summary.component.scss"],
})
export class LocationSummaryComponent {
  bundleData: QuoteLineGrouped[] = [];
  siteDetails: LocationItem[];
  currentQuote: QuoteState;
  isMobile: boolean = false;
  pipe = new DatePipe("en-US");
  intervalSubscription$: Subscription = new Subscription();
  quoteState: QuoteModelDetailsV2;
  enhanceQuoteUiFlag : boolean


  @Input() screenName: string; // Input property to receive screen name

  constructor(
    public quoteStateService: QuoteStateService,
    private toastr: ToastrService,
    private profileService: ProfileService,
    public quoteCreationService: QuoteCreationService,
    private enhanceQuoteService: EnhanceQuoteService,
    private fixedbuttonService: FixedButtonService,
  ) {
    effect(()=>{
      this.enhanceQuoteUiFlag = this.enhanceQuoteService.enhanceQuoteUiSignal()

    })

    effect(() => {
      this.isMobile = window.innerWidth < 600;
      this.bundleData = this.quoteStateService.multiLocationData();
      
    
      this.locationSummary(this.bundleData);

      let button= this.fixedbuttonService.clickedSignal();
      if(button?.name==="Next" && button?.componentName==="locationSummary"){
        this.onNext();
      }
    });

    
  }

  locationSummary(bundleData: QuoteLineGrouped[]) {
    let productQuantity = 0;
    let quantityQuoted = 0;

    let locations = {};
    bundleData.forEach((jobSiteBundle : QuoteLineGrouped) => {
      productQuantity += jobSiteBundle.quantity;

      Object.keys(jobSiteBundle).forEach((key: string) => {
        if (key.includes("newLocation")) {
          locations[key] = {
            addressId: jobSiteBundle[key]?.address?.id,
            siteName: jobSiteBundle[key]?.address?.siteName,
            placementNotes: jobSiteBundle[key]?.address?.instructions,
            bundles: [],
            expandFlag: true,
          };

          const bundle = {
            quantity: jobSiteBundle[key].quantityQuoted,
            bundleId: jobSiteBundle.product.id,
            assetName: jobSiteBundle?.assetList![0].product.description,
            serviceName: jobSiteBundle?.services![0].product.description,
            quoteLineId: jobSiteBundle.quoteLineId,
            additionalServices: jobSiteBundle.additionalServices,
          };
          quantityQuoted += jobSiteBundle[key].quantityQuoted;
          setTimeout(() => {
            if (bundle.quantity > 0) {
              locations[key]?.bundles.push(bundle);
            }
          }, 0);
        }
      });
    });
    this.siteDetails = Object.values(locations);
  }

  async onNext() {
    let productQuantity = 0;
    let quantityQuoted = 0;

    let locations = {};
    this.bundleData.forEach((jobSiteBundle : QuoteLineGrouped) => {
      productQuantity += jobSiteBundle.quantity;

      Object.keys(jobSiteBundle).forEach((key: string) => {
        if (key.includes("newLocation")) {
          const bundle = {
            quantity: jobSiteBundle[key].quantityQuoted,
            bundleId: jobSiteBundle.product.id,
            bundleName: `${jobSiteBundle?.assetList![0].product.description}  ${
              jobSiteBundle?.services![0].product.description
            }`,
            assetId: jobSiteBundle?.assetList![0].product.id,
            assetName: jobSiteBundle?.assetList![0].product.description,
            serviceId: jobSiteBundle?.services![0].product.id,
            serviceName: jobSiteBundle?.services![0].product.description,
            quoteLineId: jobSiteBundle.quoteLineId,
          };
          quantityQuoted += jobSiteBundle[key].quantityQuoted;
          if (bundle.quantity === 0) {
            return;
          }
          if (locations[key]) {
            locations[key]?.bundles.push(bundle);
          } else {
            locations[key] = {
              addressId: jobSiteBundle[key].address.id,
              siteName: jobSiteBundle[key].address.siteName,
              fileId:jobSiteBundle[key]?.attachments?.length == 1? jobSiteBundle[key].attachments[0].docId : "",
              bundles: [bundle],
            };
          }
        }
      });
    });

    if (productQuantity !== quantityQuoted) {
      this.toastr.error(
        "All quantities must be assigned to the added locations."
      );
      return;
    }
    let requestId = "";
    if (!localStorage.getItem("siteDetailsId")) {
      requestId = await this.quoteStateService.generateRequestID();
      localStorage.setItem("siteDetailsId", requestId);
    } else {
      requestId = localStorage.getItem("siteDetailsId") as string;
    }

    this.currentQuote = this.quoteStateService.getCurrentValue();
    const address = Object.assign({}, this.currentQuote.step3.SiteDetails, {
      startTime: this.currentQuote.step3.SiteDetails?.startTime
        ? this.currentQuote.step3.SiteDetails.startTime
        : "",
      endTime: this.currentQuote.step3.SiteDetails?.endTime
        ? this.currentQuote.step3.SiteDetails.endTime
        : "",
    });

    const data = {
      requestId,
      ussPortalUserId: this.profileService.userProfile$.value?.ussPortalUserId,
      startDate: this.pipe.transform(
        this.currentQuote.step1.startDate,
        "yyyy-MM-dd"
      ),

      endDate: this.currentQuote.step1.endDate,
      contactData: this.currentQuote.step3.contactData,
      addressData: address,
      quoteId: this.currentQuote.quoteId as string,
      contactId: this.currentQuote.step3.contactData?.contactId,
      addressId: this.currentQuote.step3.SiteDetails?.addressId,
      contactExist: this.currentQuote.step3.contactData?.contactId
        ? true
        : false,
      subSites: Object.values(locations),
    };
    localStorage.setItem("siteDetailsReqBody", JSON.stringify(data));
    this.saveSiteDetails(data);
  }

  async saveSiteDetails(data: any, isRefreshed: boolean = false) {
    const callBack = () => this.quoteCreationService.saveSiteDetails(data);

    this.intervalSubscription$ = this.quoteStateService.startInterval(
      callBack,
      3000,
      "siteDetails"
    );
  }


  async onContinue(){

    const requestData  :RequestSiteDetails | null = await this.enhanceQuoteService.saveSiteDetailMultipleLocation(this.bundleData)
    if(requestData){
      localStorage.setItem("siteDetailsPayload", JSON.stringify(requestData));
      this.enhanceQuoteService.startInterval(
        () => this.quoteCreationService.saveSiteDetails(requestData),
        3000,
        "siteDetails"
      );

    }

  }

  expand(index: number, expandFlag: boolean) {
    this.siteDetails[index].expandFlag = expandFlag;
  }
}
